<template>
    <div class="page">
        <div class="page_content">
            <a href="https://www.runninggrid.games" target="_blank">
                <img src="img/logo2.png" style="height: 50px; margin-top: 20px;" />
            </a>

            <el-steps :active="active" finish-status="success" style="margin-top: 20px;">
                <el-step :title="$t('room.title.page')" />
                <el-step :title="$t('time.title.page')" />
                <el-step :title="$t('people.title.page')" />
                <el-step :title="$t('name.title.page')" />
                <el-step :title="$t('price.title.page')" />
            </el-steps>

            <el-divider style="margin-top: 50px; margin-bottom: 30px;" />

            <!-- 选择房间 -->
            <div v-show="active == 0">
                <div class="info_bar">
                    Please select gaming room.
                </div>

                <div v-for="store in rooms" :key="store.storeId" class="store_div">
                    <span class="store_title">{{ store.storeName }}</span>

                    <div class="room_list">
                        <div v-for="room in store.rooms" :key="room.roomId" class="room_div">
                            <el-image :class="room.imageUrl ? 'room_image' : 'room_image_no'" :src="room.imageUrl"
                                fit="contain" />
                            <span class="room_remark" v-if="room.remark">{{ room.remark }}</span>

                            <el-button class="room_select" @click="selectRoom(room)">
                                {{ $t('button.select') }}
                            </el-button>
                        </div>
                    </div>
                </div>

                <div style="padding-bottom: 30px;"></div>
            </div>

            <!-- 选择预约时段 -->
            <div v-show="active == 1">
                <div class="info_bar">
                    For larger group bookings (more than {{ maximum }} people) or specific requirements, please
                    <a href="https://www.runninggrid.games/contact" target="_blank"> contact us</a>.
                </div>

                <div class="calendar_room">
                    Booking for {{ form.storeName }} - {{ form.roomName }}
                </div>

                <el-row>
                    <el-col :span="8">
                        <div class="calendar_title">{{ $t('time.title.calendar') }}</div>
                        <el-calendar class="calendar_background" v-model="date" @click="getBookTimes()">
                            <template #header="{ date }">
                                <SvgLeft class="calendar_button_left" @click="prevMonth()" />

                                <span>{{ date }}</span>

                                <SvgRight class="calendar_button_right" @click="nextMonth()" />
                            </template>
                        </el-calendar>
                    </el-col>
                    <el-col :span="1"></el-col>
                    <el-col :span="15">
                        <div class="calendar_content">{{ getTableTitle() }}</div>

                        <span> Select / Multi-select Session(s) </span>

                        <el-divider class="calendar_content_space" />

                        <div class="table_content">
                            <el-table :data="tableData" :height="tableHeight" :cell-style="cellStyle"
                                :show-header="false" empty-text=" ">
                                <el-table-column prop="time" label="Time" />
                                <el-table-column prop="operate" label="Operate" align="center" width="160">
                                    <template #default="scope">
                                        <el-checkbox-button class="table_button" color="#e0efc8"
                                            :disabled="!scope.row.enabled" v-model="scope.row.selected">
                                            {{ scope.row.enabled ? $t('time.button.select') : 'Unavailable' }}
                                        </el-checkbox-button>
                                    </template>
                                </el-table-column>
                            </el-table>
                        </div>
                    </el-col>
                </el-row>

                <div style="text-align: right; margin-top: 30px;">
                    <el-button class="button_prev" @click="active = 0">
                        {{ $t('button.prev') }}
                    </el-button>
                    <el-button class="button_next" @click="checkBookTimes()">
                        {{ $t('button.next') }}
                    </el-button>
                </div>
            </div>

            <!-- 选择参与人数 -->
            <div v-show="active == 2" class="div_center">
                <el-form :model="form" :rules="rules" ref="dialogForm" label-width="600px" label-position="left"
                    style="width: 800px;">
                    <div class="people_title">Booking on {{ getTableTitle() }} <br /> {{ form.storeName }} - {{ form.roomName }}</div>

                    <el-form-item class="people_item" :label="$t('people.label.adult')" prop="adult">
                        <div>
                            <el-button class="button_number" @click="adultCalc(-1)"> － </el-button>
                            <el-select v-model="people.adult" class="input_number" @change="adultChange">
                                <el-option v-for='item in adults' :value='item.value' :key='item.value'
                                    :label='item.label' />
                            </el-select>
                            <el-button class="button_number" @click="adultCalc(1)"> ＋ </el-button>
                        </div>
                    </el-form-item>
                    <el-form-item class="people_item" prop="child">
                        <template #label>
                            <div style="display: block;">
                                {{ $t('people.label.child') }}
                                <div style="font-size: 12px;color: red; margin-top: -10px;">
                                    (Must be supervised by an adult)
                                </div>
                            </div>
                        </template>

                        <el-button class="button_number" @click="childCalc(-1)"> － </el-button>
                        <el-select v-model="people.child" class="input_number" @change="childChange">
                            <el-option v-for='item in childs' :value='item.value' :key='item.value'
                                :label='item.label' />
                        </el-select>
                        <el-button class="button_number" @click="childCalc(1)"> ＋ </el-button>
                    </el-form-item>

                    <el-form-item class="people_item" prop="student">
                        <template #label>
                            <div style="display: block;">
                                {{ $t('people.label.student') }}
                                <div style="font-size: 12px;color: red; margin-top: -10px;">
                                    (must hold valid student ID)
                                </div>
                            </div>
                        </template>

                        <el-button class="button_number" @click="studentCalc(-1)"> － </el-button>
                        <el-select v-model="people.student" class="input_number" @change="studentChange">
                            <el-option v-for='item in students' :value='item.value' :key='item.value'
                                :label='item.label' />
                        </el-select>
                        <el-button class="button_number" @click="studentCalc(1)"> ＋ </el-button>
                    </el-form-item>

                    <div style="margin-top: 30px;"> {{ $t('people.label.common1') }} </div>
                    <div> {{ $t('people.label.common2') }} {{ maximum }}</div>
                    <div> {{ $t('people.label.common3') }} {{ weekend }} ({{ workday }} {{ $t('people.label.common4') }})
                    </div>

                    <div style="text-align: right; margin-top: 30px;">
                        <el-button class="button_prev" @click="active = 1">
                            {{ $t('button.prev') }}
                        </el-button>
                        <el-button class="button_next" @click="checkPeopleNumber()">
                            {{ $t('button.next') }}
                        </el-button>
                    </div>
                </el-form>
            </div>

            <!-- 输入个人信息 -->
            <div v-show="active == 3" class="div_center">
                <el-form :model="form" :rules="rules" ref="dialogForm" label-width="150px"
                    style="width: 800px; margin-right: 20px;">
                    <div class="name_title">Booking on {{ getTableTitle() }} <br /> {{ form.storeName }} - {{ form.roomName }}</div>

                    <el-form-item class="name_item" :label="$t('name.label.firstName')" prop="firstName">
                        <el-input v-model="form.firstName" clearable></el-input>
                    </el-form-item>
                    <el-form-item class="name_item" :label="$t('name.label.lastName')" prop="lastName">
                        <el-input v-model="form.lastName" clearable></el-input>
                    </el-form-item>
                    <el-form-item class="name_item" :label="$t('name.label.email')" prop="email">
                        <el-input v-model="form.email" clearable></el-input>
                    </el-form-item>
                    <el-form-item class="name_item" :label="$t('name.label.reEmail')" prop="reEmail">
                        <el-input v-model="form.reEmail" clearable></el-input>
                    </el-form-item>
                    <el-form-item class="name_item" :label="$t('name.label.mobile')" prop="mobile">
                        <el-input v-model="form.mobile" clearable></el-input>
                    </el-form-item>
                    <el-form-item class="name_item" :label="$t('name.label.teamName')" prop="teamName">
                        <el-input v-model="form.teamName" clearable></el-input>
                    </el-form-item>

                    <div style="margin-top: 30px;">
                        <span class="must_field">*</span>{{ $t('name.label.common1') }}
                    </div>
                    <div style="margin-top: 10px;"> {{ $t('name.label.common2') }} </div>

                    <div style="margin-top: 30px;">
                        <el-checkbox v-model="form.promote">{{ $t('name.label.checkbox1') }}</el-checkbox>
                    </div>
                    <div style="">
                        <el-checkbox v-model="form.agreement">
                            {{ $t('name.label.checkbox2') }}
                            <a href="https://www.runninggrid.games/activity-participation-agreement-and-waiver"
                                target="_blank">{{
                                    $t('name.label.checkbox3') }}</a>
                        </el-checkbox>
                    </div>

                    <div style="text-align: right; margin-top: 50px;">
                        <el-button class="button_prev" @click="active = 2">
                            {{ $t('button.prev') }}
                        </el-button>
                        <el-button class="button_next" @click="checkEmailTeam()">
                            {{ $t('button.next') }}
                        </el-button>
                    </div>
                </el-form>
            </div>

            <!-- 显示订单价格 -->
            <div v-show="active == 4">
                <div>
                    <el-row class="price_title" align="middle">
                        <el-col :span="14">
                            <div style="margin-left: 50px;"> Time </div>
                        </el-col>
                        <el-col :span="5">
                            <div> Number of players </div>
                        </el-col>
                        <el-col :span="5">
                            <div> Unit Price </div>
                        </el-col>
                    </el-row>

                    <el-row style="margin-top: 20px; margin-bottom: 20px;" align="middle">
                        <el-col :span="14">
                            <div style="margin-left: 50px;">
                                {{ getTableTitle() }} {{ form.timeScope }} <br /> {{ form.storeName }} - {{ form.roomName }}
                            </div>
                        </el-col>
                        <el-col :span="5">
                            <div v-if="form.adult > 0" class="price_detail">
                                {{ form.adult }} Adults
                            </div>
                            <div v-if="form.child > 0" class="price_detail">
                                {{ form.child }} Children (13 & under)
                            </div>
                            <div v-if="form.student > 0" class="price_detail">
                                {{ form.student }} Students
                            </div>
                        </el-col>
                        <el-col :span="5">
                            <div v-if="form.adult > 0" class="price_detail">
                                NZ${{ form.adultPrice }}
                            </div>
                            <div v-if="form.child > 0" class="price_detail">
                                NZ${{ form.childPrice }}
                            </div>
                            <div v-if="form.student > 0" class="price_detail">
                                NZ${{ form.studentPrice }}
                            </div>
                        </el-col>
                    </el-row>

                    <el-divider style="margin-top: 0; margin-bottom: 0px;" />

                    <el-row style="margin-top: 20px; margin-bottom: 20px;" align="middle">
                        <el-col :span="14">
                            <div style="margin-left: 50px;"> </div>
                        </el-col>
                        <el-col :span="5">
                            <div class="price_detail"> Subtotal </div>
                            <div class="price_detail"> Discount </div>
                            <div class="price_detail" v-if="form.promoValue > 0"> Promo Code </div>
                        </el-col>
                        <el-col :span="5">
                            <div class="price_detail"> NZ${{ form.totalPrice }} </div>
                            <div class="price_detail"> NZ${{ form.discount }} </div>
                            <div class="price_detail" v-if="form.promoValue > 0"> NZ${{ form.promoValue }} </div>
                        </el-col>
                    </el-row>

                    <el-row style="margin-bottom: 10px;" align="middle" v-if="form.promoReason">
                        <el-col :span="14"></el-col>
                        <el-col :span="10">
                            <div class="price_promo"> {{ form.promoReason }} </div>
                        </el-col>
                    </el-row>

                    <el-divider style="margin-top: 0; margin-bottom: 0px;" />

                    <el-row class="price_total" align="middle">
                        <el-col :span="14">
                            <div style="margin-left: 50px;"> </div>
                        </el-col>
                        <el-col :span="5">
                            <div> Total </div>
                        </el-col>
                        <el-col :span="5" class="price_total_title">
                            <div> NZ${{ form.dealPrice }} </div>
                        </el-col>
                    </el-row>

                    <el-row class="price_content" align="middle">
                        <el-col :span="14">
                            <span style="margin-left: 50px;"> Promo Code </span>
                            <el-input v-model="form.promoCode"
                                style="width: 220px; margin-left: 10px; margin-right: 10px;" clearable></el-input>
                            <el-button class="button_verify" @click="getPayment()">
                                {{ $t('button.verify') }}
                            </el-button>
                        </el-col>
                        <el-col :span="5">
                            <div> </div>
                        </el-col>
                        <el-col :span="5">
                            <div> </div>
                        </el-col>
                    </el-row>

                    <el-row class="price_content" align="middle">
                        <el-col :span="14">
                            <div style="margin-left: 50px;"> The following taxes are included in the price: </div>
                        </el-col>
                        <el-col :span="5">
                            <div> </div>
                        </el-col>
                        <el-col :span="5">
                            <div> </div>
                        </el-col>
                    </el-row>

                    <el-row class="price_content" align="middle">
                        <el-col :span="14">
                            <div style="margin-left: 50px;"> Included GST </div>
                        </el-col>
                        <el-col :span="5">
                            <div> </div>
                        </el-col>
                        <el-col :span="5">
                            <div> NZ${{ form.gst }}</div>
                        </el-col>
                    </el-row>

                    <el-divider style="margin-top: 20px; margin-bottom: 20px;" />

                    <el-row class="price_total_title" align="middle">
                        <el-col :span="14">
                            <div style="margin-left: 50px;"> Payment Method </div>
                        </el-col>
                        <el-col :span="5">
                            <div> </div>
                        </el-col>
                        <el-col :span="5">
                        </el-col>
                    </el-row>

                    <el-radio-group style="margin-left: 50px; margin-top: 30px;" v-model="form.paymentMethod">
                        <el-radio label="PM01" size="large">
                            <img style="width: 200px;" src="../../assets/svg/logo-windcave.svg" />
                        </el-radio>
                        <el-radio label="PM02" size="large">
                            <img style="width: 200px;" src="../../assets/svg/logo-paypal.svg" />
                        </el-radio>
                    </el-radio-group>

                    <div style="margin-left: 50px; margin-top: 10px; font-size: 12px;">
                        Note: You will be redirected to the payment gateway to enter your card details.
                    </div>

                    <div style="margin-left: 50px; margin-top: 10px; font-size: 12px;">
                        Apply Pay, Google Pay, Alipay are now available within Windcave. 
                    </div>

                    <el-divider style="margin-top: 30px; margin-bottom: 50px;" />
                </div>

                <div style="text-align: right; margin-top: 50px;">
                    <el-button class="button_prev" @click="active = 3">
                        {{ $t('button.prev') }}
                    </el-button>
                    <el-button class="button_next" @click="submitOrder()">
                        {{ $t('button.pay') }}
                    </el-button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import dayjs from 'dayjs';
import $http from '../../http/index.js';
// const host = 'http://127.0.0.1:8080/manage';
const host = '';

export default {
    name: 'HomeView',
    components: {},
    data() {
        return {
            active: 0,

            rooms: [],
            tableHeight: 0,
            tableData: [],

            date: new Date(),

            people: {
                adult: '2',
                child: '0',
                youth: '0',
                student: '0',
            },

            maximum: 6,
            workday: 2,
            weekend: 3,

            source: {},

            form: {
                bookDate: '',
                bookTime: '',
                roomId: '',
                roomName: '',
                storeName: '',
                playerId: '',
                firstName: '',
                lastName: '',
                email: '',
                reEmail: '',
                mobile: '',
                teamName: '',
                promote: false,
                agreement: false,
                adult: '2',
                child: '0',
                youth: '0',
                student: '0',
                peopleNumber: '0',
                timeScope: '',
                discount: '0',
                unitPrice: '0',
                totalPrice: '0',
                dealPrice: '0',
                gst: 10,
                paymentMethod: 'PM01',
                promoValue: 0,
                promoCode: '',
            },

            //验证规则
            rules: {
                firstName: [
                    {
                        required: true,
                        message: this.$t("name.check.firstName"),
                    },
                ],
                lastName: [
                    {
                        required: true,
                        message: this.$t("name.check.lastName"),
                    },
                ],
                email: [
                    {
                        required: true,
                        message: this.$t("name.check.email"),
                    },
                ],
                reEmail: [
                    {
                        required: true,
                        message: this.$t("name.check.reEmail"),
                    },
                ],
                mobile: [
                    {
                        required: true,
                        message: this.$t("name.check.mobile"),
                    },
                ],
                teamName: [
                    {
                        required: true,
                        message: this.$t("name.check.teamName"),
                    },
                ],
            },

            adults: [],
            childs: [],
            students: [],

            paymentUrl: '',
            bookId: '',
            sessionId: '',
        };
    },
    unmounted() {
        window.removeEventListener('resize', this.getTableHeight);
    },
    mounted() {
        window.addEventListener('resize', this.getTableHeight);
        this.getTableHeight();
        this.getRooms();
    },
    methods: {
        reset() {
            this.form = {
                bookDate: '',
                bookTime: '',
                roomId: '',
                storeName: '',
                roomName: '',
                playerId: '',
                firstName: '',
                lastName: '',
                email: '',
                reEmail: '',
                mobile: '',
                teamName: '',
                promote: false,
                agreement: false,
                adult: '2',
                child: '0',
                student: '0',
                peopleNumber: '0',
                timeScope: '',
                discount: '0',
                unitPrice: '0',
                totalPrice: '0',
                dealPrice: '0',
                gst: 10,
                paymentMethod: 'PM01',
                promoValue: 0,
                promoCode: '',
            };

            this.people = {
                adult: '2',
                child: '0',
                student: '0',
            };

            this.active = 0;
            this.paymentUrl = '';
            this.bookId = '';
            this.sessionId = '';

            this.adults = [];
            this.childs = [];
            this.students = [];
        },

        /*************** 公共或系统方法 ***************/

        // 提示错误信息
        error(msg) {
            this.$alert(msg, this.$t('public.error.title'), { type: 'error', dangerouslyUseHTMLString: true })
        },

        // 获得预约日期内容
        getTableTitle() {
            return dayjs(this.date).format('dddd, DD MMMM YYYY');
        },

        /*************** 公共或系统方法 ***************/




        /*************** 选择预约时间 ***************/

        // 动态改变预约时间列表高度
        getTableHeight() {
            this.tableHeight = window.innerHeight - 598;
            if (this.tableHeight < 335) {
                this.tableHeight = 335;
            }
        },

        // 预约时间列表单元格样式
        cellStyle({ row, column, rowIndex, columnIndex }) {
            if (columnIndex === 0) {
                return { 'padding-left': '20px' };
            } else {
                return ''
            }
        },

        // 预约时间日历上一个月份按钮
        prevMonth() {
            this.date = dayjs(this.date).subtract(1, 'month');
            this.getBookTimes();
        },

        // 预约时间日历下一个月份按钮
        nextMonth() {
            this.date = dayjs(this.date).add(1, 'month');
            this.getBookTimes();
        },

        // 获得房间列表
        getRooms() {
            $http.get(host + '/book/queryRooms')
                .then(res => {
                    if (res.code == 0) {
                        this.rooms = res.data;
                    } else {
                        this.error(this.$t('name.error.getBookTimes'));
                    }
                }).catch(res => {
                    this.error(this.$t('public.error.network'));
                });
        },

        // 选择房间
        selectRoom(room) {
            this.form.roomId = room.roomId;
            this.form.roomName = room.roomName;
            this.form.storeName = room.storeName;

            // 格式化预约日期
            this.form.bookDate = dayjs(this.date).format('YYYY-MM-DD');

            // 获得预约日期的可预约时间列表
            $http.post(host + '/book/queryBookTime',
                {
                    bookDate: this.form.bookDate,
                    roomId: this.form.roomId
                }
            ).then(res => {
                if (res.code == 0) {
                    this.tableData = res.data.times;
                    this.maximum = res.data.room.maximum;
                    this.workday = res.data.room.workday;
                    this.weekend = res.data.room.weekend;

                    this.adults = [];
                    this.childs = [];
                    this.students = [];
                    for (let i = 0; i <= this.maximum; i++) {
                        this.adults.push({ label: i.toString(), value: i.toString() })
                        this.childs.push({ label: i.toString(), value: i.toString() })
                        this.students.push({ label: i.toString(), value: i.toString() })
                    }

                    this.active = 1;
                } else {
                    this.error(this.$t('name.error.getBookTimes'));
                }
            }).catch(res => {
                this.error(this.$t('public.error.network'));
            });
        },

        // 获得30分钟时长的可预约时间列表
        getBookTimes() {

            // 格式化预约日期
            this.form.bookDate = dayjs(this.date).format('YYYY-MM-DD');

            // 获得预约日期的可预约时间列表
            $http.post(host + '/book/queryBookTime',
                {
                    bookDate: this.form.bookDate,
                    roomId: this.form.roomId
                }
            ).then(res => {
                if (res.code == 0) {
                    this.tableData = res.data.times;
                } else {
                    this.error(this.$t('name.error.getBookTimes'));
                }
            }).catch(res => {
                this.error(this.$t('public.error.network'));
            });
        },

        // 判断预约时间是否合理
        checkBookTimes() {
            let flag = false;
            let indexTotal = 0, indexStart = 0, indexEnd = 0, indexSelect = 0;
            let bookTimes = [];
            this.tableData.forEach(item => {
                indexTotal++;
                if (item.selected) {
                    indexSelect++;

                    if (indexSelect == 1) {
                        indexStart = indexTotal;
                    }

                    if (indexEnd < indexTotal) {
                        indexEnd = indexTotal;
                    }

                    bookTimes.push(item.time);
                }
            });

            if (indexSelect == (indexEnd - indexStart + 1)) {
                $http.post(host + '/book/checkBookTime',
                    {
                        bookDate: this.form.bookDate,
                        bookTimes: bookTimes,
                        roomId: this.form.roomId
                    }
                ).then(res => {
                    if (res.code == 0) {
                        this.active = 2;
                    } else if (res.code == 4001) {
                        this.error(this.$t('time.error.timeOccupied'));
                    } else if (res.code == 4002) {
                        this.error(this.$t('time.error.timeContinuous'));
                    } else {
                        if (typeof (res.info) == 'string') {
                            this.error(res.info);
                        } else if (typeof (res.info) == 'object') {
                            this.error(Object.values(res.info).join('<br/>'));
                        }
                    }
                }).catch(res => {
                    this.error(this.$t('public.error.network'));
                });
            } else {
                this.error(this.$t('time.error.timeContinuous'));
            }
        },

        /*************** 选择预约时间 ***************/



        /*************** 选择预约人数 ***************/

        // 判断人数是否合理
        checkPeopleNumber() {

            // 13岁以下，必须有成人陪同
            // if (parseInt(this.people.child) > 0 && parseInt(this.people.adult) <= 0) {
            //     this.error(this.$t('price.error.lackAdult'));
            //     return false;
            // }

            let week = this.date.getDay();
            let total = parseInt(this.people.adult) + parseInt(this.people.child) + parseInt(this.people.student);
            if (total > this.maximum) {
                this.error(this.$t('price.error.limitTotal').replace("6", this.maximum));
                return false;
            }

            if (week == 0 || week == 6) {

                // 周末至少3人以上
                if (total < this.weekend) {
                    this.error(this.$t('price.error.limitWeekend').replace("3", this.weekend));
                    return false;
                }
            } else {

                // 工作日至少2人以上
                if (total < this.workday) {
                    this.error(this.$t('price.error.limitWeekday').replace("2", this.workday));
                    return false;
                }
            }

            this.active = 3;
        },

        // 成人数量列表改变事件
        adultChange(value) {
            this.people.adult = value;
            this.form.adult = this.people.adult;
        },

        // 成人数量加减事件
        adultCalc(value) {
            this.people.adult = (parseInt(this.people.adult) + value).toString();
            if (this.people.adult <= 0) {
                this.people.adult = '0';
            } else if (this.people.adult >= this.maximum) {
                this.people.adult = this.maximum.toString();
            }

            this.form.adult = this.people.adult;
        },

        // 成人数量列表改变事件
        childChange(value) {
            this.people.child = value;
            this.form.child = this.people.child;
        },

        // 幼童数量加减事件
        childCalc(value) {
            this.people.child = (parseInt(this.people.child) + value).toString();
            if (this.people.child <= 0) {
                this.people.child = '0';
            } else if (this.people.child >= this.maximum) {
                this.people.child = this.maximum.toString();
            }

            this.form.child = this.people.child;
        },

        // 学生数量列表改变事件
        studentChange(value) {
            this.people.student = value;
            this.form.student = this.people.student;
        },

        // 学生数量加减事件
        studentCalc(value) {
            this.people.student = (parseInt(this.people.student) + value).toString();
            if (this.people.student <= 0) {
                this.people.student = '0';
            } else if (this.people.student >= this.maximum) {
                this.people.student = this.maximum.toString();
            }

            this.form.student = this.people.student;
        },

        /*************** 选择预约人数 ***************/



        /*************** 选择预约名称 ***************/

        // 根据邮箱和团队，判断玩家是否已经存在
        checkEmailTeam() {
            if (this.form.agreement) {
                if (this.form.email.toLowerCase() == this.form.reEmail.toLowerCase()) {
                    $http.post(host + '/book/checkEmailTeam',
                        {
                            email: this.form.email,
                            teamName: this.form.teamName
                        }
                    ).then(res => {
                        if (res.code == 0) {
                            this.active = 4;
                            this.getPayment();
                        } else if (res.code == 4000) {
                            this.form.playerId = res.data.id;
                            this.active = 4;
                            this.getPayment();
                        } else if (res.code == 4001) {
                            this.error(this.$t('name.error.checkName'));
                        } else {
                            if (typeof (res.info) == 'string') {
                                this.error(res.info);
                            } else if (typeof (res.info) == 'object') {
                                this.error(Object.values(res.info).join('<br/>'));
                            }
                        }
                    }).catch(res => {
                        this.error(this.$t('public.error.network'));
                    });
                } else {
                    this.error(this.$t('name.error.checkEmail'));
                }
            } else {
                this.error(this.$t('name.error.agreement'));
            }
        },

        /*************** 选择预约名称 ***************/



        /*************** 显示预约费用 ***************/

        // 查询本次预约的付款信息
        getPayment() {
            let bookTimes = [];
            this.tableData.forEach(item => {
                if (item.selected) {
                    bookTimes.push(item.time);
                }
            });

            $http.post(host + '/book/queryPayment',
                {
                    bookTimes: bookTimes,
                    adult: this.form.adult,
                    child: this.form.child,
                    student: this.form.student,
                    promoCode: this.form.promoCode,
                    roomId: this.form.roomId,
                    email: this.form.email,
                }
            ).then(res => {
                if (res.code == 0) {
                    if (this.form.promoCode && this.form.promoCode != res.data.promoCode) {
                        this.error(this.$t('price.error.promoCode'));
                    }

                    this.form.timeScope = res.data.timeScope;
                    this.form.adultPrice = res.data.adult;
                    this.form.childPrice = res.data.child;
                    this.form.studentPrice = res.data.student;
                    this.form.discount = res.data.discount;
                    this.form.unitPrice = res.data.unitPrice;
                    this.form.totalPrice = res.data.totalPrice;
                    this.form.dealPrice = res.data.dealPrice;
                    this.form.gst = res.data.gst;
                    this.form.promoCode = res.data.promoCode;
                    this.form.promoValue = res.data.promoValue;
                    this.form.promoReason = res.data.promoReason;
                } else if (res.code == 4001) {
                    this.active = 3;
                    this.error(this.$t('price.error.missPrice'));
                } else if (res.code == 1) {
                    this.active = 3;
                    if (typeof (res.info) == 'string') {
                        this.error(res.info);
                    } else if (typeof (res.info) == 'object') {
                        this.error(Object.values(res.info).join('<br/>'));
                    }
                }
            }).catch(res => {
                this.error(this.$t('public.error.network'));
            });
        },

        // 提交预约订单，付款
        submitOrder() {
            this.bookId = '';
            let bookTimes = [];
            this.tableData.forEach(item => {
                if (item.selected) {
                    bookTimes.push(item.time);
                }
            });
            this.form.bookTimes = bookTimes;

            $http.post(host + '/book/queryPayment',
                {
                    bookTimes: bookTimes,
                    adult: this.form.adult,
                    child: this.form.child,
                    student: this.form.student,
                    promoCode: this.form.promoCode,
                    roomId: this.form.roomId,
                    email: this.form.email,
                }
            ).then(res => {
                if (res.code == 0) {
                    if (this.form.promoCode && this.form.promoCode != res.data.promoCode) {
                        this.error(this.$t('price.error.promoCode'));
                    }

                    this.form.timeScope = res.data.timeScope;
                    this.form.adultPrice = res.data.adult;
                    this.form.childPrice = res.data.child;
                    this.form.studentPrice = res.data.student;
                    this.form.discount = res.data.discount;
                    this.form.unitPrice = res.data.unitPrice;
                    this.form.totalPrice = res.data.totalPrice;
                    this.form.dealPrice = res.data.dealPrice;
                    this.form.gst = res.data.gst;
                    this.form.promoId = res.data.promoId;
                    this.form.promoCode = res.data.promoCode;
                    this.form.promoValue = res.data.promoValue;
                    this.form.promoReason = res.data.promoReason;

                    setTimeout(() => {
                        $http.post(host + '/book/saveOrder', this.form)
                            .then(res => {
                                if (res.code == 0) {

                                    // 跳转付款页面
                                    this.bookId = res.data.bookId;

                                    if (res.data.sessionId) {
                                        this.sessionId = res.data.sessionId;
                                    }

                                    if (res.data.href) {
                                        this.paymentUrl = res.data.href;
                                        window.location.href = this.paymentUrl;
                                    }
                                } else if (res.code == 4001) {
                                    this.active = 1;
                                    this.error(this.$t('price.error.missBookTime'));
                                } else if (res.code == 4002) {
                                    this.active = 1;
                                    this.error(this.$t('price.error.timeContinuous'));
                                } else if (res.code == 4003) {
                                    this.active = 1;
                                    this.error(this.$t('price.error.timeOccupied'));
                                } else if (res.code == 4004) {
                                    this.active = 2;
                                    this.error(this.$t('price.error.lackAdult'));
                                } else if (res.code == 4005) {
                                    this.active = 2;
                                    this.error(this.$t('price.error.limitTotal'));
                                } else if (res.code == 4006) {
                                    this.active = 2;
                                    this.error(this.$t('price.error.limitWeekend'));
                                } else if (res.code == 4007) {
                                    this.active = 2;
                                    this.error(this.$t('price.error.limitWeekday'));
                                } else {
                                    if (typeof (res.info) == 'string') {
                                        this.error(res.info);
                                    } else if (typeof (res.info) == 'object') {
                                        this.error(Object.values(res.info).join('<br/>'));
                                    }
                                }
                            }).catch(res => {
                                this.error(this.$t('public.error.network'));
                            });
                    }, 1000);
                } else if (res.code == 4001) {
                    this.active = 3;
                    this.error(this.$t('price.error.missPrice'));
                } else if (res.code == 1) {
                    this.active = 3;
                    if (typeof (res.info) == 'string') {
                        this.error(res.info);
                    } else if (typeof (res.info) == 'object') {
                        this.error(Object.values(res.info).join('<br/>'));
                    }
                }
            }).catch(res => {
                this.error(this.$t('public.error.network'));
            });


        },

        /*************** 显示预约费用 ***************/
    }
};
</script>

<style>
.el-button.is-active,
.el-button:active {
    background: #e0efc8;
    border-color: #e0efc8;
    color: black;
}

.el-button:focus,
.el-button:hover {
    background: #e0efc8;
    border-color: #e0efc8;
    color: black;
}


.el-calendar-table .el-calendar-day {
    height: 50px;
}

/*隐藏下个月的日期*/
.el-calendar-table:not(.is-range) td.next {
    color: #282828;
}

/*隐藏上个月的日期*/
.el-calendar-table:not(.is-range) td.prev {
    color: #282828;
}

.el-table {
    color: white;
    background-color: black;
}

.el-table__expanded-cell {
    background-color: black;
}

.el-table th,
.el-table tr {
    background-color: black;
    height: 60px;
}

.el-table__body tr:hover>td {
    background-color: transparent !important;
}

.el-checkbox-button .el-checkbox-button__inner {
    width: 120px;
    height: 35px;
    font-size: 15px;
    font-weight: 700;
    background-color: black;
    border-radius: 7px;
    color: white;
}

.el-checkbox-button.is-checked .el-checkbox-button__inner {
    width: 120px;
    height: 35px;
    font-size: 15px;
    font-weight: 700;
    background-color: #e0efc8;
    border-radius: 7px;
    border-color: #e0efc8;
    color: black;
}

.el-calendar-day {
    display: flex;
    align-items: center;
    justify-content: center;
}

.el-calendar-table td.is-selected {
    color: black;
    background: #e0efc8;
}

.el-calendar-table .el-calendar-day:hover {
    color: black;
    background: #e0efc8;
}

.el-calendar__body {
    padding: 0;
}

.el-form-item__label {
    color: white;
}
</style>

<style scoped>
.page {
    display: flex;
    justify-content: center;
    height: 100%;
}

.page_content {
    width: 1000px;
    min-width: 1000px;
}

.store_div {
    display: flex;
    flex-direction: column;
    align-items: center;
    border: 3px solid #e0efc8;
    border-radius: 5px;
    padding: 30px 20px 0px 20px;
    margin-top: 20px;
    width: 956px;
}

.store_title {
    font-size: 18px;
    font-weight: 700;
}

.room_list {
    display: flex;
    margin-top: 30px;
    flex-wrap: wrap;
}

.room_div {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 50px;
    width: 478px;
}

.room_image_no {
    height: 280px;
    width: 400px;
}

.room_image {
    width: 400px;
}

.room_remark {
    margin-top: 20px;
    width: 400px;
    font-size: 14px;
    white-space: pre-wrap;
}

.room_select {
    margin-top: 20px;
    width: 120px;
    height: 35px;
    background-color: #e0efc8;
    border-radius: 7px;
    font-weight: 700;
    font-size: 15px;
    color: black;
}

.calendar_background {
    background-color: black;
}

.calendar_button_left {
    width: 24px;
    height: 24px;
}

.calendar_button_right {
    width: 24px;
    height: 24px;
}

.calendar_room {
    font-weight: 700;
    font-size: 32px;
    margin-bottom: 20px;
}

.calendar_title {
    display: flex;
    align-items: center;
    height: 56px;
    font-weight: 700;
    font-size: 20px;
}

.calendar_content {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    height: 80px;
    font-weight: 700;
    font-size: 32px;
}

.calendar_content_space {
    margin-bottom: 0px;
}

.table_button {
    width: 120px;
    height: 35px;
    font-size: 15px;
    font-weight: 700;
}

.table_content {
    display: flex;
    flex-direction: column;
}

.button_next {
    width: 160px;
    height: 35px;
    background-color: #e0efc8;
    border-radius: 7px;
    font-weight: 700;
    font-size: 15px;
    color: black;
}

.button_prev {
    width: 160px;
    height: 35px;
    background-color: black;
    border-radius: 7px;
    border-width: 3px;
    border-color: #e0efc8;
    font-weight: 700;
    font-size: 15px;
    color: white;
}

.button_verify {
    width: 120px;
    height: 35px;
    background-color: #e0efc8;
    border-radius: 7px;
    font-weight: 700;
    font-size: 15px;
    color: black;
}

.name_title {
    display: flex;
    align-items: center;
    height: 56px;
    font-weight: 700;
    font-size: 32px;
    margin-bottom: 50px;
}

.name_item {
    display: flex;
    align-items: center;
    height: 50px;
}

.people_title {
    display: flex;
    align-items: center;
    height: 56px;
    font-weight: 700;
    font-size: 32px;
    margin-bottom: 50px;
}

.people_item {
    display: flex;
    align-items: center;
    height: 60px;
}

.button_number {
    width: 39px;
    height: 32px;
    background-color: #e0efc8;
    border-radius: 7px;
    border-width: 3px;
    border-color: #e0efc8;
    font-weight: 700;
    font-size: 15px;
    color: black;
    font-size: 18px;
}

.input_number {
    width: 100px;
    margin-left: 10px;
    margin-right: 10px;
}

.div_center {
    display: flex;
    justify-content: center;
}

.price_title {
    height: 48px;
    background-color: #e0efc8;
    margin-top: 50px;
    color: black;
}

.price_detail {
    line-height: 40px;
    color: white;
}

.price_promo {
    font-size: 12px;
    line-height: 20px;
    font-weight: 200;
    color: white;
}

.price_content {
    height: 60px;
    color: white;
}

.price_total {
    height: 80px;
    color: white;
}

.price_total_title {
    color: white;
    font-size: 30px;
}

.must_field {
    color: var(--el-color-danger);
    margin-right: 4px;
}

.thank_button {
    width: 85px;
    height: 85px;
}

.thank_info_1 {
    font-size: 30px;
    font-weight: 700;
}

.thank_info_2 {
    margin-top: 15px;
    font-size: 15px;
    font-weight: 100;
}

.info_bar {
    padding: 20px 50px 20px 50px;
    height: 28px;
    background: #E0EFC8;
    border-radius: 10px;
    padding-top: 20px;
    padding-bottom: 20px;
    font-size: 18px;
    font-weight: 700;
    color: black;
    margin-bottom: 30px;
}
</style>
